import underConstruction from "./under-construction.svg";
import mbj from "./MBJ.png";
import munchies from "./Munchies.png";
import nima from "./Ni3ma.png";
import amd from "./AMD.png";
import db from "./DB.png";
import "./App.css";

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <img
          src={underConstruction}
          className="under-construction-icon"
          alt="under construction"
        />
        <div className="app-text">
          <p className="text-bold">
            Sorry! Under construction to serve you better!
          </p>
          <p>
            Meanwhile check out our newly launched loyalty programme Zaboon on{" "}
            <a
              href="https://darwishbros.com/zaboon/"
              target="_blank"
              rel="noreferrer"
            >
              www.darwishbros.com/zaboon
            </a>
          </p>
          <h3 className="margin-small">Contact Info</h3>
          <p className="text-small">
            For recruitment related inquiries at Darwish Bros please contact us
            on{" "}
            <a href="emailto:tawzeef@darwishbros.com">
              tawzeef@darwishbros.com
            </a>
          </p>
          <p className="text-small">
            For marketing inquiries please contact us on{" "}
            <a href="emailto:amdmarketing@darwishbros.com">
              AMDmarketing@darwishbros.com
            </a>
          </p>
          <p className="text-small">
            For general and corporate inquiries please contact us on{" "}
            <a href="emailto:info@darwishbros.com">info@darwishbros.com</a>
          </p>
          <div className="app-logos text-center">
            <img src={db} alt="Darwish Bros" className="app-logo" />
          </div>
          <div className="app-logos">
            <img src={mbj} alt="MBJ" className="app-logo" />
            <img src={munchies} alt="Munchies" className="app-logo" />
            <img src={nima} alt="Ni3ma" className="app-logo" />
            <img src={amd} alt="AMD Marketing" className="app-logo" />
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
